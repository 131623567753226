// /////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2021 Autodesk, Inc. All rights reserved.
//
//                     ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
// /////////////////////////////////////////////////////////////////////

import {LoginResult} from "../dataModel/LoginResult";
import {CustomerUser, DeckardAuthData, ProblemDetails} from "../clients/Classes";
import {GetErrorMessage} from "../Utility";
import {deleteCookie, getCookie, setCookie} from "../CookieUtils";
import {ServiceBase} from "./ServiceBase";
import {IClient} from "../clients/Client";

export class AuthService extends ServiceBase {
  private _client: IClient;

  readonly tokenCookieName = 'rt';
  readonly idCookieName = 'uid';

  private AccessToken: string | undefined;
  private AutodeskToken: string | undefined;
  private Expiry: Date | undefined;

  IsLoggedIn: boolean = false;
  CurrentUser: CustomerUser | undefined;

  constructor(client: IClient) {
    super();
    this._client = client;
  }

  Login(autodeskToken: string): Promise<LoginResult> {
    if (autodeskToken === '' || autodeskToken === null || autodeskToken === undefined) {
      return Promise.resolve(LoginResult.GetFailureResult('Autodesk token is required'))
    }

    const callbackOverride = process.env.REACT_APP_USE_CALLBACK_OVERRIDE === '1'
      ? process.env.REACT_APP_CALLBACK_URL
      : undefined;

    return this.TryPromiseWithCatch(() =>
      this._client
        .callback(autodeskToken, callbackOverride)
        .then(auth => {
          this.SetLocalData(auth, false);
          return LoginResult.GetSuccessResult(auth.authToken!, auth.authTokenExpiry!, '');
        })
        .catch(error => {
          this.HandleError('Login', error);
          const mainError = GetErrorMessage(error, 'Login');
          const finalError = error instanceof ProblemDetails || error.hasOwnProperty('detail')
            ? `${mainError} - ${error.detail}`
            : mainError;
          return LoginResult.GetFailureResult(finalError);
        })
    );
  }

  AttemptStoredLogin(): Promise<LoginResult> {
    if (this.AccessToken != null && this.Expiry && this.Expiry > new Date()) {
      return Promise.resolve(LoginResult.GetSuccessResult(this.AccessToken!, this.Expiry!, this.AutodeskToken!));
    }

    return this.RefreshToken();
  }

  GetCurrentToken(): Promise<string | undefined> {
    if (this.AccessToken != null && this.Expiry && this.Expiry > new Date()) {
      return Promise.resolve(this.AccessToken);
    }

    // Need to refresh
    return this.RefreshToken()
      .then(result => result.Success ? result.AccessToken : undefined);
  }

  Logout(): void {
    this.IsLoggedIn = false;
    this.AccessToken = undefined;
    sessionStorage.removeItem('rt');
    sessionStorage.removeItem('uid');
    localStorage.removeItem('rt');
    localStorage.removeItem('uid');
    deleteCookie(this.tokenCookieName);
    deleteCookie(this.idCookieName);
    this.CurrentUser = undefined;
  }

  private SetLocalData(authResult: DeckardAuthData, remember: boolean): void {
    if (remember) {
      localStorage.setItem('rt', authResult.refreshToken!);
      setCookie(this.tokenCookieName, authResult.refreshToken!);
      localStorage.setItem('uid', authResult.userId!);
      setCookie(this.idCookieName, authResult.userId!);
    }
    this.AccessToken = authResult.authToken;
    this.AutodeskToken = authResult.adskAccessToken;
    sessionStorage.setItem('rt', authResult.refreshToken!);
    sessionStorage.setItem('uid', authResult.userId!);
    this.IsLoggedIn = true;
    this.Expiry = authResult.authTokenExpiry;
    this.CurrentUser = authResult.user;
  }

  private RefreshToken(): Promise<LoginResult> {
    const sessionRefresh = sessionStorage.getItem('rt');
    const sessionUserId = sessionStorage.getItem('uid');

    let refreshToken = sessionRefresh == null || sessionRefresh === ''
      ? localStorage.getItem('rt')
      : sessionRefresh;

    let userId = sessionUserId == null || sessionUserId === ''
      ? localStorage.getItem('uid')
      : sessionUserId;

    if (refreshToken == null || refreshToken === '') {
      refreshToken = getCookie(this.tokenCookieName) ?? null;
    }

    if (userId == null || userId === '') {
      userId = getCookie(this.idCookieName) ?? null;
    }

    if (refreshToken == null || refreshToken === '' || userId == null || userId === '') {
      return Promise.resolve(LoginResult.GetFailureResult('No refresh token or user id found'));
    }

    return this.TryPromiseWithCatch(() =>
      this._client.refreshToken(userId!, refreshToken!)
        .then(authObject => {
          // Set remember to false here otherwise we store data even if the user didn't request remember.
          // We are refreshing from the current stored token, so it will never change the token anyway, no
          // need to store it again.
          this.SetLocalData(authObject, false);

          return LoginResult.GetSuccessResult(authObject.authToken!, authObject.authTokenExpiry!, authObject.adskAccessToken!);
        })
        .catch(error => {
          this.HandleError('Login', error);
          return LoginResult.GetFailureResult(GetErrorMessage(error, 'Refresh'));
        })
    );
  }

  private HandleError(operation: string, error: any) {
    console.error(error);

    switch (operation) {
      case 'Login':
        // If we are logging in and get a 401 the credentials were bad
        if (error instanceof ProblemDetails && error.status === 401) {
          LoginResult.GetFailureResult(`We couldn't connect with Autodesk.`);
          return;
        }
        break;
      case 'Refresh':
        // If we tried to refresh and got 401 the token is not valid, log user out
        if (error instanceof ProblemDetails && error.status === 401) {
          this.Logout();
          return;
        }
        break;
    }

    const errorMessage = error instanceof ProblemDetails ? error.title : error;
    alert(`Error in ${operation}: ${errorMessage}`);
  }
}