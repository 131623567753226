////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {PAGES, PATHS} from "../Constants";
import {CenteringContainer} from '../CommonStyledComponents';
import {ClientProvider} from "../clients/ClientProvider";
import {Link} from "react-router-dom";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import {GetErrorMessage} from "../Utility";

const authService = ClientProvider.AuthService;

const Landing = () => {
  const navigate = useNavigate();
  const [primaryFeedback, setPrimaryFeedback] = useState<string | undefined>();
  const [secondaryFeedback, setSecondaryFeedback] = useState<string | undefined>();
  const [tertiaryFeedback, setTertiaryFeedback] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [showRetry, setShowRetry] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');

  useEffect(() => {
    let isMounted = true;

    if (code == null || code === '') {
      setPrimaryFeedback(`Sorry, we could not access Replication Tool`)
      setSecondaryFeedback(`No code found`);
      setShowRetry(true);
      return;
    }

    setLoading(true);
    authService.Login(code)
      .then(result => {
        if (!isMounted) {
          return;
        }

        setLoading(false);
        if (result.Success) {
          const stateParam = queryParams.get('state');
          const passedState = stateParam == null ? undefined : JSON.parse(decodeURIComponent(stateParam));
          const redirectUrl = passedState?.returnPath ?? `${PATHS[PAGES.ROOT]}/${PATHS[PAGES.TASK]}`;
          navigate(redirectUrl);
        } else {
          setPrimaryFeedback(`Sorry, we could not access Replication Tool`);
          setSecondaryFeedback(`There was a problem signing you in.  Please contact your Autodesk account administrator to resolve this issue.`);
          setTertiaryFeedback(result.Message);
          setShowRetry(true);
        }
      })
      .catch(error => alert(GetErrorMessage(error, 'Login')));

    return () => {
      isMounted = false;
    }
  }, []);

  return (
    <CenteringContainer style={{flexDirection: "column"}}>
      {loading && <ProgressRing size={'xlarge'}/>}
      <div style={{fontSize: "medium", fontWeight: "bold"}}>{primaryFeedback}</div>
      <div style={{marginTop: '1em'}}>{secondaryFeedback}</div>
      <div style={{marginTop: '1em', fontSize: 'small'}}>{tertiaryFeedback}</div>
      {showRetry && <Link to={PATHS[PAGES.DOOR]}>Try Again</Link>}
    </CenteringContainer>
  );
};

export default Landing;